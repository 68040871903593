@font-face {
  font-family: 'ITC Avant Garde Std Bk';
  src: url('./css/fonts/ITCAvantGardeStd-DemiObl.eot');
  src: url('./css/fonts/ITCAvantGardeStd-DemiObl.eot?#iefix') format('embedded-opentype'),
  url('./css/fonts/ITCAvantGardeStd-DemiObl.woff2') format('woff2'),
  url('./css/fonts/ITCAvantGardeStd-DemiObl.woff') format('woff'),
  url('./css/fonts/ITCAvantGardeStd-DemiObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}



@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('./css/fonts/ITCAvantGardeStd-Md.eot');
    src: url('./css/fonts/ITCAvantGardeStd-Md.eot?#iefix') format('embedded-opentype'),
        url('./css/fonts/ITCAvantGardeStd-Md.woff2') format('woff2'),
        url('./css/fonts/ITCAvantGardeStd-Md.woff') format('woff'),
        url('./css/fonts/ITCAvantGardeStd-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('./css/fonts/ITCAvantGardeStd-Bold.eot');
    src: url('./css/fonts/ITCAvantGardeStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('./css/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
        url('./css/fonts/ITCAvantGardeStd-Bold.woff') format('woff'),
        url('./css/fonts/ITCAvantGardeStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Demi';
    src: url('./css/fonts/ITCAvantGardeStd-Demi.eot');
    src: url('./css/fonts/ITCAvantGardeStd-Demi.eot?#iefix') format('embedded-opentype'),
        url('./css/fonts/ITCAvantGardeStd-Demi.woff2') format('woff2'),
        url('./css/fonts/ITCAvantGardeStd-Demi.woff') format('woff'),
        url('./css/fonts/ITCAvantGardeStd-Demi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

